.input {
  // margin-right: 5px;

  box-sizing: border-box;
  border: none;
  outline: none;
  caret-color: #3b3b3b;
  color: #3b3b3b;
  // font-weight: 500;
  font-size: 16px;
  border-radius: 4px;
  font-family: 'IBM Plex Sans', sans-serif;

  background: #2b2b2b0f;
  padding: 10px 0 10px 15px;

  height: 36px;
  width: 100%;
}

.search {
  font-family: IBM Plex Sans, sans-serif;
  font-size: 16px;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  border: none;
  background: #2b2b2b0f;
  padding: 9px 16px;
  border-radius: 4px;
  cursor: pointer;
  color: #3b3b3b;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  background: transparent;
  border: 1px solid #59acd6;
  transition: color .1s;
  margin-top: 8px;

  svg {
    color: #3b3b3b;
    transition: color .1s;
    margin-left: 8px;
  }
}

.text {
  font-size: 16px;
  // font-weight: 500;
  color: #bebebe;
  display: flex;
  margin-bottom: 24px;
  position: relative;
}

.dropdownContainer {
  position: absolute;
  top: 50%;
  right: 83px;
  transform: translateY(-50%);
  width: 138px;
  box-sizing: border-box;
  right: 95px; // with svg
  transition: color .1s;
  font-size: 16px;

  svg {
    position: absolute;
    right: 5;
    top: 50%;
    transform: translateY(-50%);
    color: #3b3b3b;
    cursor: pointer;
    height: 18px;
    transition: color .1s;
  }

  // &:hover {
  //   color: black;

  //   svg {
  //     color: black;
  //   }
  //   .dropdownValue {
  //     color: black;
  //   }
  // }
}

.dropdownValue {
  font-family: IBM Plex Sans, sans-serif;
  border: none;
  background: transparent;
  padding: 9px 16px;
  padding-right: 40px;
  border-radius: 4px;
  cursor: pointer;
  color: #3b3b3b;
  border: 1px solid #59acd6;
  line-height: 1;
  box-sizing: border-box;
  transition: color .1s;
}

.dropdownItemsContainer {
  position: absolute;
  font-family: IBM Plex Sans, sans-serif;
  display: flex;
  flex-direction: column;
  // gap: 12px;
  width: 100%;
  // padding: 9px 10px;
  // padding-left: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  bottom: 0;
  color: #3b3b3b;
  z-index: 999;
  cursor: pointer;
  box-shadow: 0 3px 3.84px 1.16px #0c0c0c2b;
  background: #f4f3fd;
  margin-top: -3px;
  padding: 0 4px;
  transition: color .1s;

  & > div {
    border-top: 1px solid #3b3b3b21;
    padding: 9px 0;
    padding-left: 16px;

    // &:hover {
    //   color: black;
    // }

    span {
      margin-left: -3px;
      margin-top: 1px;
      display: block;
    }
  }

  & > div:first-child {
    border-top: none;
  }
}

.buttonsOutside {
  flex-direction: column!important;
  gap: 8px!important;
  margin-top: 10px;

  .dropdownValue {
    border: none;
  }

  .dropdownContainer {
    position: relative!important;
    border-radius: 4px;
    top: 0;
    right: 0;
  }

  .dropdownContainer, .search {
    position: relative;
    transform: none;
    width: 100%;
    background: #2b2b2b0f;
    border: none;
    top: 0;
    right: 0;
  }

  // .search svg {
  //   display: none;
  // }
}

@media only screen and (max-width: 386px) {
  .text {
    flex-direction: column;
    gap: 8px;
  }

  .dropdownValue {
    border: none;
  }

  .dropdownContainer {
    position: relative!important;
    border-radius: 4px;
    top: 0;
    right: 0;
  }

  .dropdownContainer, .search {
    position: static;
    transform: none;
    width: 100%;
    background: #f4f3fd;
    border: none;
  }

  // .search svg {
  //   display: none;
  // }
}

@media only screen and (max-width: 945px) {
  .text {
    font-size: 16px;
    
    button {
      font-size: 16px;
      color:#f4f3fd;
      background: #3b3b3b!important;

      svg {        
        color:#f4f3fd;
      }
    }

    input {
      height: 36px;
      font-size: 16px;

      color:#f4f3fd;
      caret-color: #f4f3fd;
      background: #3b3b3b;

      &::placeholder {
        color:#f4f3fd;
      }
    }

    .dropdownContainer {
      background: #3b3b3b;
      color:#f4f3fd;
      svg {        
        color:#f4f3fd;
      }
    }

    .dropdownValue {
      color:#f4f3fd;
      svg {        
        color:#f4f3fd;
      }
    }
  }
}