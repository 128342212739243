.newQuestion {
  margin-top: 25px;
  width: 100%;
  position: relative;
  
  textarea {
    color: #2b2b2b;
    &::placeholder {
      color: #2b2b2b;
      font-size: 16px;
    }
    font-size: 16px;
    background: #2b2b2b0f;
    // box-shadow: 0 3px 3.84px 2.16px #0c0c0c2b;
  }
}

@media only screen and (max-width: 400px) {
  .newQuestion {
    margin-top: 14px;
  }
}

@media only screen and (max-width: 945px) {
  .newQuestion textarea {
    height: 110px!important;
  }
}

.button {
  font-family: IBM Plex Sans, sans-serif;
  background: #3eb5f1;
  color: #f1f1f1;
  box-shadow: 0 0px 23.84px 8.16px #3eb5f163;
  cursor: pointer;
  border: none;
  border-radius: 29px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  font-size: 18px;
  font-weight: 500;
  transition: box-shadow .3s ease-out;
  display: flex;
  position: absolute;
  right: 11px;
  bottom: -11px;
  transform: translateY(-50%);
}

.button:not(:disabled):hover {
  box-shadow: 0 3px 9.84px -0.84px #3eb5f12e;
}

.button:disabled {
  background: #8d9193;
  cursor: not-allowed;
  box-shadow: 0 0 23.84px 8.16px #8d91935c;
}