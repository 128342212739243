import React, { forwardRef } from 'react'
import projectXUI from 'project-x-ui'
import { useLocalStorage } from '@rehooks/local-storage'
import decodeJwt from 'jwt-decode'
import useCookie from 'react-use-cookie'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useMixpanel } from '../../../../../../hooks/mixpanel'
import { useSrpcApi } from '../../../../../../hooks/useSrpcApi'

const { leafs: { ActionsPanel: ActionsPanelUI } } = projectXUI

export const handleTwitterLogin = async (state = '') => {
  try {
    const response = await axios.get(`${process.env.HOST}/api/auth/twitter${state}`, {
      withCredentials: true
    })
    window.location.href = response.data.url
  } catch (error) {
    console.error('Error logging in with Twitter:', error)
  }
}

export const ActionsPanel = forwardRef(({ jwt, testUsers, showMyHistory, changeUser }, ref) => {
  const [,, removeJwt] = useLocalStorage('jwt') // eslint-disable-line
  const [,, removeRealJwt] = useLocalStorage('jwt_real') // eslint-disable-line
  const [, setUserToken] = useCookie('jwt', '0')
  const { mixpanel } = useMixpanel()

  let username

  if (jwt) {
    const jwtDecoded = decodeJwt(jwt)
    username = jwtDecoded.username
  }
  const srpcApi = useSrpcApi()
  const navigate = useNavigate()

  const signOut = () => {
    mixpanel.current.track('Logout')
    setUserToken(undefined, { days: -1 })
    removeJwt()
    removeRealJwt()
  }

  const createUser = async ({ email, name, answer, picture, parentMessageId, shortId }) => {
    const { jwt, error } = await srpcApi.createUserNew({ email, name, picture, answer, shortId, parentMessageId })

    if (!error) {
      mixpanel.current.track('Sign Up Success', {
        type: 'action-panel-button'
      })

      navigate(`/?jwt=${jwt}`)
    }
  }

  return (
    <ActionsPanelUI
      ref={ref}
      showMyHistory={showMyHistory}
      testUsers={testUsers}
      changeUser={changeUser}
      handleTwitterLogin={() => handleTwitterLogin()}
      logout={signOut}
      username={username}
      createUser={createUser}
    />
  )
})
