/* eslint-disable */
import React, { useContext, useEffect } from 'react'
import projectXUI from 'project-x-ui'
import { useSelector, useDispatch } from 'react-redux'
import FlipMove from 'react-flip-move'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { useNavigate } from 'react-router-dom'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { useLocalStorage } from '@rehooks/local-storage'

// if setting up the CSS is tricky, you can add this to your page somewhere:
// <link rel="stylesheet" href="https://unpkg.com/react-spring-bottom-sheet/dist/style.css" crossorigin="anonymous">
// import 'react-spring-bottom-sheet/dist/style.css'

import { SET_SORT_AND_FILTER_FOR_MESSAGES } from '../../../../../../actions/setSortAndFilterForMessages'

import { useMixpanel } from '../../../../../../hooks/mixpanel'

const {
  leafs: { Search, SortQuestions },
  context: { MainScreenSwipeContext }
} = projectXUI

Search.prototype = {}
SortQuestions.prototype = {}

const initialState = { sort: 'Most Answered', filter: 'All Time' }

export const SortAndSearch = ({ jwt }) => {
  const { screenName, showSearch, setShowSearch } = useContext(MainScreenSwipeContext)
  const questionsSearchResult = useSelector(state => state.questionsSearchResult)
  const sortAndFilterForMessages = useSelector(state => state.sortAndFilterForMessages)

  const navigate = useNavigate();

  const dispatch = useDispatch()
  const { mixpanel } = useMixpanel()

  const ref = useDetectClickOutside({ onTriggered: () => setShowSearch(false) })

  const [sortAndFilter, setSortAndFilterLS] = useLocalStorage('sortAndFilter')

  useEffect(() => {
    if (sortAndFilter) {
      setSortAndFilter(sortAndFilter.sort, sortAndFilter.filter, false)
    } else {
      setSortAndFilter(initialState.sort, initialState.filter, false)
    }
  }, [Boolean(sortAndFilter)])

  const searchFn = async (entity, search) => {
    if (entity === 'Questions') {
      navigate(`/questions?search=${search}`)
    } else {
      navigate(`/users?search=${search}`)
    }

    setShowSearch(false)
  }

  const setSortAndFilter = (sort, filter, update = true) => {
    dispatch({
      type: SET_SORT_AND_FILTER_FOR_MESSAGES,
      payload: { sort: sort || sortAndFilterForMessages.sort , filter: filter || sortAndFilterForMessages.filter }
    })
    if (update) {
      setSortAndFilterLS(JSON.stringify({ sort: sort || sortAndFilterForMessages.sort , filter: filter || sortAndFilterForMessages.filter }))
    }
    setShowSearch(false)
  }

  if (screenName === 'uninitialized') {
    return null
  }

  return (
    <>
      <BottomSheet open={screenName && showSearch} initialFocusRef={false}>
        <div ref={ref}>
          <div style={{ height: 345, width: '100%', display: 'flex', justifyContent: 'center' }} ref={ref}>
            <div style={{ width: 241 }}>
              {/* <FlipMove typeName={null} appearAnimation={'fade'} enterAnimation={'fade'} leaveAnimation={'fade'}> */}
                <Search buttonsOutside search={searchFn} />
                <SortQuestions
                  sort={sortAndFilterForMessages.sort}
                  duration={sortAndFilterForMessages.filter}
                  setSort={sort => setSortAndFilter(sort)}
                  setDuration={duration => setSortAndFilter(null, duration)}
                  getMessages={setSortAndFilter}
                  disabledDuration={questionsSearchResult.search}
                />
              {/* </FlipMove> */}
            </div>
          </div>
        </div>
      </BottomSheet>
      {
        !screenName && (
          <div style={{ width: '241px' }}>
            <FlipMove typeName={null} appearAnimation={'fade'} enterAnimation={'fade'} leaveAnimation={'fade'}>
              <Search buttonsOutside search={searchFn} />
              <SortQuestions
                sort={sortAndFilterForMessages.sort}
                duration={sortAndFilterForMessages.filter}
                setSort={sort => {
                  mixpanel.current.track('Update Sort', {
                    sort
                  })
                  setSortAndFilter(sort)
                }}
                setDuration={duration => {
                  mixpanel.current.track('Update Filter', {
                    filter: duration
                  })
                  setSortAndFilter(null, duration)
                }}
                getMessages={setSortAndFilter}
                disabledDuration={questionsSearchResult.search}
              />
              <button
                className='reset-filters-button'
                onClick={() => {
                  mixpanel.current.track('Reset Filter and Sort')
                  setSortAndFilter(initialState.sort, initialState.filter)
                }}
              >
                reset
              </button>
              <button className='landing-button' onClick={() => navigate(jwt ? '/about' : '/')}>About</button>
            </FlipMove>
          </div>
        )
      }
    </>
  )
}
