import { SET_SORT_AND_FILTER_FOR_MESSAGES } from '../actions/setSortAndFilterForMessages'

const initialState = { sort: null, filter: null }

export default (state = initialState, action) => {
  const { type, payload } = action

  const updateStateByFunctionName = {
    [SET_SORT_AND_FILTER_FOR_MESSAGES]: () => {
      if (payload.sort) {
        return { ...state, filter: payload.filter || initialState.filter, sort: payload.sort || initialState.sort }
      }
      if (payload.filter) {
        return { ...state, sort: payload.sort || initialState.sort, filter: payload.filter || initialState.filter }
      }
      return state
    }
  }

  const actionHandler = updateStateByFunctionName[type] || (() => state)

  return actionHandler()
}
