import React, { createContext, useContext, useEffect, useRef } from 'react'

const MixpanelContext = createContext()

const mock = {
  identify: () => {
    console.log('mock mixpanel identify for local')
  },
  track: (event) => {
    console.log(`mock mixpanel track for local, event: ${event}`)
  },
  people: {
    set: () => {
      console.log('mock mixpanel people.set for local')
    }
  }
}

export const MixpanelProvider = ({ children, token }) => {
  const mixpanelRef = useRef()
  useEffect(() => {
    const main = async () => {
      if (process.env.HOST !== 'https://krill-immense-randomly.ngrok-free.app') {
        // Import Mixpanel SDK
        const mixpanel = (await import('mixpanel-browser')).default

        // Near entry of your product, init Mixpanel
        mixpanel.init('18286ddb7a0f100b017feae63a1e08fd', {
          debug: process.env.HOST === 'https://krill-immense-randomly.ngrok-free.app',
          track_pageview: 'url-with-path',
          persistence: 'localStorage'
        })
        mixpanel.init(token)
        mixpanelRef.current = mixpanel
        window.mixpanel = mixpanel
      } else {
        mixpanelRef.current = mock
        window.mixpanel = mock
      }
    }
    main()
  }, [token])

  return (
    <MixpanelContext.Provider value={{ mixpanel: mixpanelRef }}>
      {children}
    </MixpanelContext.Provider>
  )
}

export const useMixpanel = () => {
  const context = useContext(MixpanelContext)
  if (!context) {
    throw new Error('useMixpanel must be used within a MixpanelProvider')
  }
  return context
}
