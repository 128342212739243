import React, { forwardRef, useContext } from 'react'
import projectXUI from 'project-x-ui'
import toast from 'react-hot-toast'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'

import coinSvg from './assets/dollar.svg?url'

import { useSrpcApi } from '../../../../../../hooks/useSrpcApi'
import { useMixpanel } from '../../../../../../hooks/mixpanel'

const {
  leafs: { NewQuestion },
  context: { MainScreenSwipeContext }
} = projectXUI

export const MessageInput = forwardRef(({ jwt }, ref) => {
  const srpcApi = useSrpcApi()
  const navigate = useNavigate()
  const { setIsModalOpen } = useContext(MainScreenSwipeContext)
  const { mixpanel } = useMixpanel()

  const saveQuestion = async content => {
    if (!jwt) {
      console.log('cannot ask anything without being authorized')
      return null
    }

    mixpanel.current.track('Question Asked')

    const { reward, message } = await srpcApi.saveMessage({ jwt, content })

    toast(() => (
      <div
        style={{ fontFamily: 'IBM Plex Sans', display: 'flex', flexDirection: 'column', gap: 24, width: 300, boxSizing: 'border-box', margin: '5px 10px' }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <p style={{ fontWeight: 500, fontSize: '1.2em' }}>🎉 Question Sent!</p>
          <CloseIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              mixpanel.current.track('Question Successfully Asked Toast Dissmissed')
              toast.dismiss()
            }}
          />
        </div>
        <button
          style={{ marginTop: 0, width: '100%' }}
          className='open-app-button'
          onClick={() => {
            mixpanel.current.track('Question Open from Toast')
            navigate(`/questions/${message.shortId}`)
            toast.dismiss()
          }}
        >
          Open
        </button>

        {
          reward
            ? (
              <div
                style={{
                  paddingTop: 20,
                  borderTop: '1px solid #b3b3b3',
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 4,
                  width: '100%',
                  alignItems: 'center',
                  position: 'relative'
                }}
              >
                Your reward: <span style={{ maxWidth: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '1.2em', fontWeight: 500, marginLeft: 8 }}>{reward}</span>
                <div style={{ position: 'relative', height: 24, left: -13 }}>
                  <div
                    className="coin coin--animated"
                    style={{ '--coin-to-x': 'calc(-100px + 24px)', '--coin-to-y': 'calc(-105px + 24px)', '--coin-delay': '0.3s' }}
                  />
                  <div
                    className="coin coin--animated"
                    style={{ '--coin-to-x': 'calc(-70px + 24px)', '--coin-to-y': '-90px', '--coin-delay': '0.1s' }}
                  />
                  <div
                    className="coin coin--animated"
                    style={{ '--coin-to-x': 'calc(-30px + 24px)', '--coin-to-y': '-125px', '--coin-delay': '0s' }}
                  />
                  <div
                    className="coin coin--animated"
                    style={{ '--coin-to-x': 'calc(10px + 24px)', '--coin-to-y': '-130px', '--coin-delay': '0.2s' }}
                  />
                  <div
                    className="coin coin--animated"
                    style={{ '--coin-to-x': 'calc(30px + 24px)', '--coin-to-y': '-100px', '--coin-delay': '0.1s' }}
                  />
                  <div
                    className="coin coin--animated"
                    style={{ '--coin-to-x': 'calc(70px + 24px)', '--coin-to-y': '-95px', '--coin-delay': '0.4s' }}
                  />
                  <div
                    className="coin coin--animated"
                    style={{ '--coin-to-x': 'calc(100px + 24px)', '--coin-to-y': '-100px', '--coin-delay': '0.2s' }}
                  />
                  <img
                    style={{
                      height: 24,
                      width: 54,
                      boxSizing: 'border-box',
                      padding: '0 15px'
                    }}
                    src={coinSvg}
                    alt="coin"
                  />
                </div>
              </div>
              )
            : (
                <p style={{ paddingTop: 20, borderTop: '1px solid #b3b3b3' }}>
                  <button
                    className='connect-wallet-toast-button'
                    onClick={() => {
                      toast.dismiss()
                      setIsModalOpen(true)
                      mixpanel.current.track('Question Successfully Asked Toast -> Connect Wallet Click')
                    }}
                  >
                      Connect wallet
                    </button>
                    &nbsp;to get rewards
                </p>
              )
      }
      </div>
    ), { duration: Infinity })
  }

  return (
    <NewQuestion ref={ref} saveQuestion={jwt ? saveQuestion : null} />
  )
})
