import React, { useContext, useEffect, forwardRef, useState } from 'react'
import projectXUI from 'project-x-ui'
import { useLocalStorage } from '@rehooks/local-storage'
import { useSelector } from 'react-redux'
import useCookie from 'react-use-cookie'
import decodeJwt from 'jwt-decode'
import FlipMove from 'react-flip-move'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import MessageInput from './components/MessageInput'
import MessageList from './components/MessageList'
import ActionsPanel from './components/ActionsPanel'
import { SortAndSearch } from './components/SortAndSearch'

import { useMixpanel } from '../../../../hooks/mixpanel'

const {
  shallow: { Sidebar, MainScreen, GroupsContainer },
  context: { MainScreenSwipeContext }
} = projectXUI

const testUsers = [
  // { fullName: 'Andrew Tate', jwt: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNvYnJhdGF0ZSIsImZ1bGxOYW1lIjoiQW5kcmV3IFRhdGUiLCJwaWN0dXJlVXJsIjoiaHR0cHM6Ly9wYnMudHdpbWcuY29tL3Byb2ZpbGVfaW1hZ2VzLzE3Mjg4MzcwMTMwMjM4OTU1NTIvbkNIcmRqbGhfNDAweDQwMC5qcGciLCJ0d2l0dGVyVmVyaWZpZWQiOnRydWUsImZvbGxvd2VyQ291bnQiOiI5MTAwMDAwIiwidXNlcklkIjoiNjYyM2MyMzI0NDIyZTMwMTBhM2UzN2ViIiwiYWRkcmVzcyI6InRlc3QifQ.308gHNrrSIT8OlxHcv3DydL9_8IWZwsi9mlmZNB0E0g' },
  { fullName: 'Patrick Bet-David', jwt: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiI2NjgwMGFlMjc0Njg0ZDhjN2IwNmUwYTYiLCJfaWQiOiI2NjgwMGFlMjc0Njg0ZDhjN2IwNmUwYTYiLCJmdWxsTmFtZSI6IlBhdHJpY2sgQmV0LURhdmlkIiwicGljdHVyZVVybCI6Imh0dHBzOi8vcGJzLnR3aW1nLmNvbS9wcm9maWxlX2ltYWdlcy8xNzQ1OTI5OTA1NTc1OTkzMzQ1L0NrZlFxa190XzQwMHg0MDAuanBnIiwiZm9sbG93ZXJDb3VudCI6OTczMDAwLCJ1c2VybmFtZSI6InBhdHJpY2tiZXRkYXZpZCIsInR3aXR0ZXJWZXJpZmllZCI6ImJsdWUifQ.VOEU7V8EljouxkcCLv2_PGYXUkSBiu_aXhC2jdkSMn0' },
  // { fullName: 'SNEAKO', jwt: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiI2NjMyNTgyZjAzN2YwOWY1MTIxNmM5YjUiLCJfaWQiOiI2NjMyNTgyZjAzN2YwOWY1MTIxNmM5YjUiLCJmdWxsTmFtZSI6IlNORUFLTyIsInBpY3R1cmVVcmwiOiJodHRwczovL3Bicy50d2ltZy5jb20vcHJvZmlsZV9pbWFnZXMvMTc0NjI2MTU3OTYyMzE5ODcyMC9uWFFNX3E5VV80MDB4NDAwLmpwZyIsImZvbGxvd2VyQ291bnQiOjgwODAwMCwidXNlcm5hbWUiOiJzbmVha28iLCJhZGRyZXNzIjoidGVzdCIsInR3aXR0ZXJWZXJpZmllZCI6dHJ1ZX0.o0k5l_6lR6HWNYmPqQB_QZpgJxcPLrGK4VgY8rBN7uM' }
]

MessageInput.prototype = {}
ActionsPanel.prototype = {}

const MainScreenWithQuestions = forwardRef(({ isAdmin, jwt, selectUser, showMyHisthory, changeUser }, ref) => {
  return (
    <div style={{ height: '100%', marginRight: 241 }} ref={ref}>
      <MainScreen
        style={{
          height: 'calc(-255px + 100dvh)',
          overflow: 'scroll',
          marginTop: 10
        }}
      >
        <MessageList jwt={jwt} onUserClick={selectUser} />
      </MainScreen>

      <div style={{ position: 'fixed', bottom: 10, width: 500 }}>
        <FlipMove typeName={null} appearAnimation={'fade'} enterAnimation={'fade'} leaveAnimation={'fade'}>
          <MessageInput jwt={jwt} />
          <ActionsPanel jwt={jwt} showMyHistory={showMyHisthory} testUsers={isAdmin ? testUsers : []} changeUser={changeUser} />
        </FlipMove>
      </div>
    </div>
  )
})

const SidebarWithQuestions = forwardRef(({ isAdmin, jwt, showMyHistory, selectUser, changeUser }, ref) => {
  const sortAndFilterForMessages = useSelector(state => state.sortAndFilterForMessages)
  const value = useContext(MainScreenSwipeContext)
  const { mixpanel } = useMixpanel()

  return (
    <Sidebar
      ref={ref}
      style={{
        height: '100dvh',
        boxSizing: 'border-box',
        width: 'calc(100% - 20px)',
        position: 'relative',
        padding: 0
      }}
    >
      <h3
        onClick={e => {
          e.stopPropagation()
          mixpanel.current.track('Open Bottom Sheet')
          value.setShowSearch(true)
        }}
        style={{ color: '#2b2b2b', fontWeight: 300, marginTop: 10, marginBottom: 5, display: 'flex' }}
      >
        {sortAndFilterForMessages.filter === 'All Time' ? '' : 'Last'} {' '}
        {sortAndFilterForMessages.filter}
        {' '}
        {sortAndFilterForMessages.sort}
        <ArrowDropDownIcon />
      </h3>
      <GroupsContainer
        // id='questions-desktop-scroll-target'
        className='mobile-messages-container'
      >
        <MessageList jwt={jwt} onUserClick={selectUser} />
      </GroupsContainer>
      <div style={{
        width: 'calc(100dvw - 20px)',
        position: 'fixed',
        bottom: '10px',
        boxSizing: 'border-box'
      }}>
        <FlipMove typeName={null} appearAnimation={'fade'} enterAnimation={'fade'} leaveAnimation={'fade'}>
          <MessageInput jwt={jwt} />
          <ActionsPanel jwt={jwt} showMyHistory={showMyHistory} testUsers={isAdmin ? testUsers : []} changeUser={changeUser} />
        </FlipMove>
      </div>
    </Sidebar>
  )
})

export const AuthorizedScreen = ({ jwt, setPayout, setAddress }) => {
  const { screenName, setSkipScreen } = useContext(MainScreenSwipeContext)

  const navigate = useNavigate()

  const usersByAnswer = useSelector(state => state.usersByAnswer)

  useEffect(() => {
    const skipScreen = usersByAnswer.results ? ['groups'] : ['groupContent', 'groups']

    setSkipScreen(skipScreen)
  }, [Boolean(usersByAnswer.results)])

  const selectUser = async (user) => {
    const userId = typeof user === 'string' ? user : user._id

    navigate(`/users/${userId}`)
  }

  const [, setLSJWT] = useLocalStorage('jwt')
  const [realJwt] = useLocalStorage('jwt_real')
  const [, setCookieJWT] = useCookie('jwt', '0')
  const [user, setUser] = useState()

  const changeUser = (fullName) => {
    if (!fullName) {
      setLSJWT(realJwt)
      setCookieJWT(realJwt)
    } else {
      const { jwt } = testUsers.find(i => i.fullName === fullName)
      setLSJWT(jwt)
      setCookieJWT(jwt)
    }
  }

  useEffect(() => {
    if (jwt) {
      const decoded = decodeJwt(jwt)
      setUser(decoded)

      if (decoded.address && decoded.address !== 'test') {
        setAddress(decoded.address)

        Promise.all([import('@wagmi/core'), import('../../../../config')])
          .then(async ([wagmiCore, { config: configPromise }]) => {
            const config = await configPromise

            const balance = await wagmiCore.getBalance(config, {
              address: decoded.address,
              token: process.env.ASK_TOKEN_CONTRACT_ADDRESS
            })

            setPayout(balance?.formatted)
          })
      }
    }
  }, [jwt])

  let realUser = null
  if (realJwt) {
    const decoded = decodeJwt(realJwt)
    realUser = decoded
  }

  if (screenName === 'uninitialized') {
    return null
  }

  return (
    <>
      <Helmet encodeSpecialCharacters={false}>
        <title>poll.cc</title>
        <meta name="description" content="Public voting on social or political issues."></meta>
      </Helmet>
      <SortAndSearch jwt={jwt} />
      {
        screenName
          ? (
              <SidebarWithQuestions
                key={user ? 1 : 0}
                showMyHistory={() => selectUser(user)}
                selectUser={selectUser}
                isAdmin={realUser?.admin}
                jwt={jwt}
                changeUser={changeUser}
              />
            )
          : (
              <MainScreenWithQuestions
                key={user ? 1 : 0}
                selectUser={selectUser}
                showMyHisthory={() => selectUser(user)}
                isAdmin={realUser?.admin}
                jwt={jwt}
                changeUser={changeUser}
              />
            )
      }
    </>
  )
}
